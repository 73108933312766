<template>
	<div>
		<tap :taps="tap" :isMore="false"></tap>
		<newList @clkCup="clkCup" :policy="policy.data"></newList>
		<!-- 分页 -->
		<template v-if="policy.data.length">
			<div class="block">
			    <el-pagination
					background
					:page-size="perPage(policy.per_page)"
					layout="total, prev, pager, next, jumper"
					:total="policy.total"
					@current-change="changePg">
			    </el-pagination>
			</div>
		</template>
		<template v-else>
			<el-empty description="暂无数据"></el-empty>
		</template>
	</div>
</template>

<script>
	import newList from "@/components/new/newList"
	export default {
		data() {
			return {
				page:1,
				tap:[],
				policy:{}
			}
		},
		components:{
			newList
		},
		created() {
			this.tap[0] = this.$route.query.tap
			this.getAll()
		},
		computed:{
			perPage() {
				return (tt)=>{
					let tts = parseInt(tt)
					return tts
				}
			}
		},
		methods:{
			// 查找所有数据
			getAll() {
				let url = this.$route.query.url
				let catid = this.$route.query.catid
				this.$axios.get(url,{
					params:{
						catid,
						page:this.page,
						limit:10
					}
				}).then(res=>{
					if(res.code) {
						this.policy = res.data
					}
				})
			},
			changePg(e) {
				this.page = e
				this.getAll()
			},
			// 点击每一行
			clkCup(index,id) {
				let catid = this.$route.query.catid
				this.$router.push({name:"newdetails",query:{index,id,type:1}})
			}
		}
	}
</script>

<style lang="less" scoped>
	/deep/ .not-tap {
		margin-bottom: 0;
	}
	
</style>